@font-face
  font-family: $icon-font-name
  src: url('./vj4icon.eot')
  src: url('./vj4icon.eot#iefix') format('embedded-opentype'),
    url('./vj4icon.woff2') format('woff2'),
    url('./vj4icon.woff') format('woff'),
    url('./vj4icon.ttf') format('truetype')
  font-weight: normal
  font-style: normal

.icon
  font-family: $icon-font-name !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


.icon-account--circle:before
  content: $icon-account--circle

.icon-add:before
  content: $icon-add

.icon-award:before
  content: $icon-award

.icon-block:before
  content: $icon-block

.icon-bold:before
  content: $icon-bold

.icon-book:before
  content: $icon-book

.icon-calendar:before
  content: $icon-calendar

.icon-check--circle:before
  content: $icon-check--circle

.icon-check:before
  content: $icon-check

.icon-chevron_left:before
  content: $icon-chevron_left

.icon-chevron_right:before
  content: $icon-chevron_right

.icon-close--circle:before
  content: $icon-close--circle

.icon-close:before
  content: $icon-close

.icon-code:before
  content: $icon-code

.icon-comment--multiple:before
  content: $icon-comment--multiple

.icon-comment--text:before
  content: $icon-comment--text

.icon-comparison:before
  content: $icon-comparison

.icon-copy:before
  content: $icon-copy

.icon-crown:before
  content: $icon-crown

.icon-debug:before
  content: $icon-debug

.icon-delete:before
  content: $icon-delete

.icon-download:before
  content: $icon-download

.icon-edit:before
  content: $icon-edit

.icon-emoji:before
  content: $icon-emoji

.icon-enlarge:before
  content: $icon-enlarge

.icon-erase:before
  content: $icon-erase

.icon-expand_less:before
  content: $icon-expand_less

.icon-expand_more:before
  content: $icon-expand_more

.icon-facebook:before
  content: $icon-facebook

.icon-feeling-lucky:before
  content: $icon-feeling-lucky

.icon-file:before
  content: $icon-file

.icon-filter:before
  content: $icon-filter

.icon-flag:before
  content: $icon-flag

.icon-formula:before
  content: $icon-formula

.icon-github:before
  content: $icon-github

.icon-global:before
  content: $icon-global

.icon-google_plus:before
  content: $icon-google_plus

.icon-heart--outline:before
  content: $icon-heart--outline

.icon-heart:before
  content: $icon-heart

.icon-help:before
  content: $icon-help

.icon-help2:before
  content: $icon-help2

.icon-homework:before
  content: $icon-homework

.icon-hourglass:before
  content: $icon-hourglass

.icon-info--circle:before
  content: $icon-info--circle

.icon-info:before
  content: $icon-info

.icon-insert--image:before
  content: $icon-insert--image

.icon-insert--link:before
  content: $icon-insert--link

.icon-italic:before
  content: $icon-italic

.icon-lab:before
  content: $icon-lab

.icon-link--external:before
  content: $icon-link--external

.icon-link:before
  content: $icon-link

.icon-linkedin:before
  content: $icon-linkedin

.icon-logout:before
  content: $icon-logout

.icon-mail:before
  content: $icon-mail

.icon-ordered_list:before
  content: $icon-ordered_list

.icon-platform--android:before
  content: $icon-platform--android

.icon-platform--chromeos:before
  content: $icon-platform--chromeos

.icon-platform--ios:before
  content: $icon-platform--ios

.icon-platform--linux:before
  content: $icon-platform--linux

.icon-platform--mac:before
  content: $icon-platform--mac

.icon-platform--mobile:before
  content: $icon-platform--mobile

.icon-platform--unknown:before
  content: $icon-platform--unknown

.icon-platform--windows:before
  content: $icon-platform--windows

.icon-play:before
  content: $icon-play

.icon-preview:before
  content: $icon-preview

.icon-qq:before
  content: $icon-qq

.icon-quote:before
  content: $icon-quote

.icon-refresh:before
  content: $icon-refresh

.icon-reply:before
  content: $icon-reply

.icon-schedule--fill:before
  content: $icon-schedule--fill

.icon-schedule:before
  content: $icon-schedule

.icon-search:before
  content: $icon-search

.icon-security:before
  content: $icon-security

.icon-send:before
  content: $icon-send

.icon-settings:before
  content: $icon-settings

.icon-shrink:before
  content: $icon-shrink

.icon-sliders:before
  content: $icon-sliders

.icon-stack:before
  content: $icon-stack

.icon-star--outline:before
  content: $icon-star--outline

.icon-star:before
  content: $icon-star

.icon-statistics:before
  content: $icon-statistics

.icon-stopwatch:before
  content: $icon-stopwatch

.icon-tag:before
  content: $icon-tag

.icon-twitter:before
  content: $icon-twitter

.icon-unordered_list:before
  content: $icon-unordered_list

.icon-upload:before
  content: $icon-upload

.icon-user--multiple:before
  content: $icon-user--multiple

.icon-user:before
  content: $icon-user

.icon-vote--down:before
  content: $icon-vote--down

.icon-vote--up:before
  content: $icon-vote--up

.icon-warning:before
  content: $icon-warning

.icon-web:before
  content: $icon-web

.icon-wechat:before
  content: $icon-wechat

.icon-wrench:before
  content: $icon-wrench

